<template>
  <div
    class="d-flex pa-2 mx-1 white drop-shadow"
    style="border-radius: 5px; position: relative"
    :class="{ 'my-light-blue': id === selectedId }"
    @click="$emit('click')"
  >
    <div class="d-flex align-center" style="width: 56px">
      <v-img
        v-if="!isImageError"
        ref="vimage"
        height="56"
        :src="image"
        width="56"
      >
        <template v-slot:placeholder>
          <v-row align="center" class="fill-height ma-0" justify="center">
            <v-progress-circular color="grey lighten-2" indeterminate />
          </v-row>
        </template>
      </v-img>
      <v-card
        v-else
        height="56"
        width="56"
        class="d-flex align-center justify-center"
        color="grey lighten-3"
        flat
      >
        <v-icon v-if="bridge_category.id === '1'" x-large>
          $NormalBridgeIcon
        </v-icon>
        <v-icon v-else-if="bridge_category.id === '2'" x-large>
          $CrossBridgeIcon
        </v-icon>
        <v-icon v-else-if="bridge_category.id === '3'" x-large>
          $OverpassBridgeIcon
        </v-icon>
        <v-icon v-else-if="bridge_category.id === '4'" x-large>
          $InterChangeBridgeIcon
        </v-icon>
      </v-card>
    </div>
    <div
      class="pl-3"
      :style="
        sr ? 'max-width: calc(100% - 111px);' : 'max-width: calc(100% - 56px);'
      "
    >
      <div class="primary--text ellipsis-text" style="width: 100%">
        {{ name || '(ไม่มีชื่อ)' }}
        {{ direction ? `(${direction})` : '' }}
      </div>
      <div
        class="d-block d-sm-flex grey--text text--darken-1 ellipsis-text"
        style="font-size: 0.875rem; width: 100%"
      >
        <div class="mr-1">
          ทล. {{ parseInt(code_road) }} กม. {{ getKm(km) }}
        </div>
        <div>ความยาว {{ parseFloat(bridge_length).toLocaleString() }} ม.</div>
      </div>
    </div>
    <div
      v-if="sr"
      style="font-size: 0.8125rem; position: absolute; right: 8px; top: 11px"
    >
      SR = {{ parseFloat(sr).toFixed(2) }}
    </div>
    <div
      v-if="distance"
      style="font-size: 0.8125rem; position: absolute; right: 8px; bottom: 4px"
    >
      <v-icon color="primary" @click.stop="$emit('clickLabel')">
        mdi-map-marker-outline
      </v-icon>
      <span> {{ distance.toFixed(2) }} กม. </span>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'id',
    'name',
    'address',
    'no',
    'image',
    'direction',
    'code_road',
    'code_section',
    'km',
    'bridge_length',
    'distance',
    'sr',
    'selectedId',
    'bridge_category'
  ],
  data: () => ({
    isImageError: false
  }),
  mounted () {
    if (!this.image) {
      this.isImageError = true
    }
  },
  methods: {
    getKm (km) {
      const numKm = Math.floor(km / 1000).toString()
      return `
        ${numKm}
        +
        ${km.toString().substring(numKm.length).padStart(3, '0')}
      `
    }
  }
}
</script>

<style lang="scss" scoped>
.ellipsis-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
